module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-try-ghost/gatsby-browser.js'),
      options: {"plugins":[],"ghostConfig":{"development":{"apiUrl":"http://localhost:2368","contentApiKey":"a796e77bedb641f7f0ca21576e"},"production":{"apiUrl":"http://localhost:2368","contentApiKey":"a796e77bedb641f7f0ca21576e"}},"siteConfig":{"siteUrl":"https://blog.danielkawalsky.com","infiniteScroll":true,"postsPerPage":3,"siteTitleMeta":"Dan Codes It","siteDescriptionMeta":"Some of my programming finds, curiosities, and projects","shortTitle":"Code Blog","siteIcon":"favicon.png","backgroundColor":"#e9e9e9","themeColor":"#728950","verbose":false,"severity":"info"},"mediaConfig":{"gatsbyImages":true,"gatsbyImageLoading":"lazy","gatsbyImageFadeIn":true},"routes":{"basePath":"/","collections":[]}},
    },{
      plugin: require('../node_modules/gatsby-theme-ghost-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"defaultModeDark":false,"overrideOS":false},
    },{
      plugin: require('../node_modules/gatsby-theme-ghost-toc/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
